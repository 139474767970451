import autoBind from 'auto-bind';

class ReportApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  createFeeStructure(payload) {
    return this.api.post("/reporting/fee_structures", payload);
  }
  getFeeStructures(merchantUuid) {
    const headers = (merchantUuid == null ? void 0 : merchantUuid.length) > 0 ? {
      "X-Merchant-UUIDs": merchantUuid.join(",")
    } : void 0;
    return this.api.get("/reporting/fee_structures", { headers });
  }
  getMerchantReport(data) {
    return this.api.get(
      `/reporting/merchant_report/${data.merchant_uuid}/${data.backend_uuid}`,
      {
        fromDate: data.fromDate,
        toDate: data.toDate
      }
    );
  }
  getUpcomingPayouts(data) {
    return this.api.get("/reporting/upcoming_payouts", {
      fromDate: data.fromDate,
      toDate: data.toDate
    });
  }
  createUpcomingPayoutReport(data) {
    return this.api.post(`/reporting/upcoming_payouts/${data.upcoming_payout_url}`, {
      amount_eur: data.amount_eur,
      happened_at: data.happened_at
    });
  }
  createMerchantAdjustment(data) {
    return this.api.post(`/reporting/adjustments/${data.merchant_legal_uuid}/merchant`, {
      happened_at: data.happened_at,
      adjustment_type: data.adjustment_type,
      fund_type: data.fund_type,
      amount_eur: data.amount_eur,
      reason: data.reason,
      backend_legal_uuid: data.backend_legal_uuid
    });
  }
  getLegalEntitiesBackend() {
    return this.api.get(`/reporting/legal_entities/backend`);
  }
  getLegalEntitiesMerchant() {
    return this.api.get(`/reporting/legal_entities/merchant`);
  }
}

export { ReportApi };
